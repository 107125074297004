import React, { useContext, useState } from 'react';
import { standalone_SuperAdmin_Testcase_columns, standalone_Testcase_columns } from '../constants/constants';
import { Table } from 'antd';
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import ToggleEllipsis from '../components/toggleEllipsis'
import { useGetAllTestCasesByScenarioIdQuery, useLazyGetTestDataSbyVersionIdQuery, useUpdateTestCaseStatusMutation } from '../redux/testCases/testCases.api';
import _ from 'lodash';
import Dropdown from '../shared/components/Dropdown';
import PayaptTable from '../shared/components/Table';
import VersionSelection from './versionSelection';
import MUIButton from "../shared/components/MUIButton";
import { colors } from '../shared/colors';
import { setDpOptions, updateSelectedTestCasePayload, updateTestScenarioName } from '../redux/common/common.slice';
import XTwoWayLabelSwitch from '../shared/components/XTwoWayLabelSwitch';
import XSwitch from '../shared/components/XSwitch';
import { ALERT_SEVERITY } from '../shared/constants/constants';
import { SnackbarContext } from '../shared/context/SnackbarContext.Context';
import MessageIdDropdown from './MessageIdDropdown';

const TestcaseTable = (props) => {
  // const columns = standalone_Testcase_columns;

  const dispatch = useDispatch();
  const { userRole } = useSelector((state) => state?.common?.userDetails);

  let columns;
  if (userRole?.name === 'Super Admin') {
    columns = standalone_SuperAdmin_Testcase_columns;
  } else if (userRole?.name === 'Admin' || userRole?.name === 'Tester') {
    columns = standalone_Testcase_columns;
  }

  const { testCaseScenarioId, setIsModalVisible, onTestcaseClick,
    isAllTestcasesSelected, setSelectedTestCasesToRun, selectedTestCases, disabledSelection, searchTerm, messageTypePayload } = props;

  const pageSize = useSelector((state) => state?.common?.pageSize);
  const initialPageOptions = {
    pageNo: 0,
    pageSize,
    customerTestCaseScenarioId: testCaseScenarioId,
    testCaseName: searchTerm
  }
  const [pageOptions, setPageOptions] = React.useState({...initialPageOptions
    // pageNo: 0,
    // pageSize,
    // customerTestCaseScenarioId: testCaseScenarioId,
    // testCaseName: searchTerm
  });
  const [xSelectedRows, setXselectedRows] = useState([]);
  const [selectedVersions, setSelectedVersions] = useState({})
  const [isActive, setIsActive] = useState(true);
  const Snackbar = useContext(SnackbarContext);
  const { selectedTestCasePayload } = useSelector((state) => state?.common)
  // const [data, setData] = React.useState({});

  // const [ getAllTestCasesByScenarioId,{ data, isLoading, refetch }] = useGetAllTestCasesByScenarioIdQuery({ ...pageOptions }, {
  //   skip: testCaseScenarioId === "" // this will skip the fetchCall when testCaseScenarioId value is empty
  // });
  const queryResult = useGetAllTestCasesByScenarioIdQuery({ ...pageOptions }, {
    skip: testCaseScenarioId === ""
  });

  React.useEffect(() => {
    if(!selectedTestCasePayload || selectedTestCasePayload?.length === 0 ){
      setXselectedRows([])
    }
  }, [selectedTestCasePayload]);

  // Access the properties directly from the query result object
  const getAllTestCasesByScenarioId = queryResult.getAllTestCasesByScenarioId;
  const data = queryResult.data;
  const isLoading = queryResult.isLoading;
  const refetch = queryResult.refetch;
  const [updateActiveStatus] = useUpdateTestCaseStatusMutation();
  // const [getTestDataSbyVersionId] = useLazyGetTestDataSbyVersionIdQuery();

  // useEffect(() => {
  //   async function fetchDD() {
  //     const contentData = await Promise.all(
  //       testCasesData.contenet?.map(async (xEl) => {
  //         return {
  //           ...xEl,
  //           versions: await getTestDataSbyVersionId({ customerTestCaseVersionId: xEl?.customerTestCaseVerionId })
  //             .unwrap()
  //             .then((xRes) => {
  //               return xRes
  //             })
  //         }
  //       })
  //     )
  //     const { contenet, ...restProps } = testCasesData;
  //     const manipulatedData = {
  //       ...restProps,
  //       contenet: contentData
  //     }
  //     setData(manipulatedData);
  //   }
  //   if (testCasesData && testCasesData?.contenet?.length > 0) {
  //     fetchDD();
  //   }
  // }, [testCasesData])
  useEffect(() => {
    refetch();
    setPageOptions(initialPageOptions)
  }, [searchTerm])


  //no-pre-requisite
  // useEffect(() => {
  //   if (!_.isEmpty(data)) {
  //     const versions = {}
  //     _.forEach(data?.contenet, d => {
  //       const isAllSel = _.isEqual(_.size(d.customerTestDataInTestCase), 1);
  //       versions[d.customerTestCaseId] = {
  //         value: !_.isEmpty(d.customerTestDataInTestCase) ? [d.customerTestDataInTestCase[0].customerTestDataId] : [],
  //         isAllSelected: _.isEqual(_.size(d.customerTestDataInTestCase), 1)
  //       }
  //     });
  //     setSelectedVersions(versions);
  //   }
  //   // }, data)
  // }, [data])

  //pre-requisitedata
  
  useEffect(() => {
    if (!_.isEmpty(data)) {
      const versions = {}
      _.forEach(data?.contenet, d => {
        const isAllSel = _.isEqual(_.size(d.customerTestDataInTestCase), 1);
        versions[d.customerTestCaseId] = {
          data: !_.isEmpty(d.customerTestDataInTestCase) ? d.customerTestDataInTestCase[0].customerTestDataId : "",
          messageId: !_.isEmpty(d.customerTestDataInTestCase) ? selectedTestCasePayload?.find(item => item?.customerTestCaseId === d.customerTestCaseId)?.customerTestDataId[0]?.messageId : null,
          isAllSelected: _.isEqual(_.size(d.customerTestDataInTestCase), 1)
        }
      });
      setSelectedVersions(versions);
    }
    // }, data)
  }, [data])

  useEffect(() => {
    if (isAllTestcasesSelected) {
      selectAllTestCases();
    }
    refetch({ ...pageOptions, pageNo: 0 });
  }, [])

  useEffect(() => {
    dispatch(updateSelectedTestCasePayload([]))
  }, [messageTypePayload])

  useEffect(() => {
    if (isAllTestcasesSelected) {
      selectAllTestCases();
    }
  }, [isAllTestcasesSelected, data])

  const selectAllTestCases = () => {
    if (!_.isEmpty(data) && !_.isEmpty(data.contenet) && !disabledSelection) {
      const mappedData = _.map(data.contenet, d => ({
        customerTestCaseId: d.customerTestCaseId,
        customerTestCaseVersionId: d.customerTestCaseVerionId,
        // customerTestDataId: _.get(selectedVersions[d.customerTestCaseId], 'value', null)
        //pre-requisite
        customerTestDataId: [{
          data: _.get(selectedVersions[d.customerTestCaseId], 'data', null),
          messageId: _.get(selectedVersions[d.customerTestCaseId], 'messageId', null)
        }]
      }));
      dispatch(updateSelectedTestCasePayload(mappedData))
      setSelectedTestCasesToRun(mappedData, testCaseScenarioId, true);
    }
  }
  const [testData, setTestData] = useState();
  //no-pre-requisite
  // const onVersionChange = (customerTestCaseId, val, totalVersions) => {
  //   const updatedData = { ...selectedVersions };
  //   updatedData[customerTestCaseId] = {
  //     value: val,
  //     isAllSelected: _.isEqual(_.size(val), totalVersions)
  //   };
  //   setSelectedVersions(updatedData);

  //   const mappedData = _.map(xSelectedRows, d => ({
  //     customerTestCaseId: d.customerTestCaseId,
  //     customerTestCaseVersionId: d.customerTestCaseVerionId,
  //     customerTestDataId: _.get(updatedData[d.customerTestCaseId], 'value', null)
  //   }));
  //   setSelectedTestCasesToRun(mappedData, testCaseScenarioId, false);
  // }


  // const onVersionChange = (customerTestCaseId, val, totalVersions) => {
  //   console.log("val", customerTestCaseId, val, totalVersions);
  //   setTestData(val)
  //   const updatedData = { ...selectedVersions };
  //   // console.log("updatedData", updatedData);
  //   updatedData[customerTestCaseId] = {
  //     data: val,
  //     isAllSelected: _.isEqual(_.size(val), totalVersions)
  //   };
  //   console.log("updatedData-2", updatedData);
  //   setSelectedVersions(updatedData);

  //   const mappedData = _.map(xSelectedRows, d => (console.log("xSelectedRows",d),{
  //     customerTestCaseId: d.customerTestCaseId,
  //     customerTestCaseVersionId: d.customerTestCaseVerionId,
  //     customerTestDataId: _.get(updatedData[d.customerTestCaseId].data, 'data', null)
  //   }));
  //   console.log("mappedData", mappedData);
  //   setSelectedTestCasesToRun(mappedData, testCaseScenarioId, false);
  // }

  const onVersionChange = (customerTestCaseId, val, totalVersions) => {
    setTestData(val)

    // Assuming selectedVersions is initialized properly
    const updatedData = { ...selectedVersions };

    updatedData[customerTestCaseId] = {
      data: val,
      isAllSelected: _.isEqual(_.size(val), totalVersions)
    };
    
    setSelectedVersions(updatedData);

    const mappedData = _.map(xSelectedRows, (d, i) => {
      const updateData = updatedData[d?.customerTestCaseId];
      const testData = _.get(updatedData[d?.customerTestCaseId], 'data', selectedTestCasePayload?.find(item => item?.customerTestCaseId === d?.customerTestCaseId)?.customerTestDataId[0]?.data);
      const customerTestDataId = testData ? [{ data: testData, messageId: selectedTestCasePayload?.find(item => item?.customerTestCaseId === d?.customerTestCaseId)?.customerTestDataId[0]?.messageId }] : null;
      // messageId[i]?.customerTestDataId[0]?.messageId
      return {
        customerTestCaseId: d?.customerTestCaseId,
        customerTestCaseVersionId: d?.customerTestCaseVerionId,
        customerTestDataId
      };
    });

    dispatch(updateSelectedTestCasePayload(mappedData))
    setSelectedTestCasesToRun(mappedData, testCaseScenarioId, false);
  }


  const [messageId, setMessageId] = useState();
  // const onMessageIdChange = (customerTestCaseId, val) => {
  //   console.log("MESS",val);
  //   setMessageId(val);
  //   const updateData = { ...selectedVersions };
  //   updateData[customerTestCaseId].messageId = val;
  //   // updateData[customerTestCaseId] = {
  //   //   data:
  //   //   messageId: val,
  //   // };
  //   console.log("updateDataID", updateData);
  //   setSelectedVersions(updateData);

  //   const mappedData = _.map(xSelectedRows, d => ({
  //     customerTestCaseId: d.customerTestCaseId,
  //     customerTestCaseVersionId: d.customerTestCaseVerionId,
  //     customerTestDataId: _.get(updateData[d.customerTestCaseId].messageId, 'messageId', null)
  //   }));
  //   console.log("mappedData3", mappedData);
  // }

  const onMessageIdChange = (customerTestCaseId, val) => {
    setMessageId(val)
    // Assuming `selectedVersions` is a state variable
    const updateData = { ...selectedVersions };
    updateData[customerTestCaseId].messageId = val;

    // Assuming `setSelectedVersions` is a function to update state
    setSelectedVersions(updateData);
    const mappedData = _.map(xSelectedRows, d => {
      const updatedData = updateData[d?.customerTestCaseId];
      const customerTestData = {
        data: updatedData?.data, // Assuming this data comes from somewhere
        messageId: _.get(updatedData, 'messageId', null)
      };
     
      return {
        customerTestCaseId: d?.customerTestCaseId,
        customerTestCaseVersionId: d?.customerTestCaseVerionId,
        customerTestDataId: [
          {
            data: customerTestData?.data ? customerTestData?.data : selectedTestCasePayload?.find(item => item?.customerTestCaseId === d?.customerTestCaseId)?.customerTestDataId[0]?.data || updatedData?.data,
            // updatedData?.data,
            messageId: customerTestData?.messageId && val && d.customerTestCaseId === customerTestCaseId ? val : selectedTestCasePayload?.find(item => item?.customerTestCaseId === d?.customerTestCaseId)?.customerTestDataId[0]?.messageId
            // updatedData?.messageId
          }
        ]
        // customerTestData?.messageId ? [customerTestData] : "abc"
      };
    });

    // console.log("mappedData-1", mappedData);
    const diff = {
      testCaseId: customerTestCaseId,
      isMessageId: true,
      statePayload: mappedData
    }
    // console.log("mappedData-12", diff);
    // setMessageId(mappedData);
    dispatch(updateSelectedTestCasePayload(diff))
    setSelectedTestCasesToRun(mappedData, testCaseScenarioId, false);
  };


  const payload = {
    // testCaseId:data?.contenetcustomerTestCaseId,
    // active:isActive
  }
  const [testCases, setTestCases] = useState();
  

  useEffect(() => {
    if (data) {
      setTestCases(data?.contenet)
      dispatch(setDpOptions(data?.contenet))
    }
  }, [data])
  // console.log("PAY",data?.preReqSelection);
  // const tempData
  const [payloadData, setPayloadData] = useState([]);
  const handleActiveInactive = async (isActive, testCaseId) => {
    try {
      // Update the local state
      // console.log("testCaseId", testCaseId);
      const updatedTestCases = testCases?.map(testCase =>
        testCase.customerTestCaseId === testCaseId ? { ...testCase, active: isActive } : testCase
      );
      // console.log('updatedTestCases', updatedTestCases);
      setTestCases(updatedTestCases);

      const existingPayloadIndex = payloadData.findIndex(item => item.customerTestCaseId === testCaseId);
      if (existingPayloadIndex !== -1) {
        // Update the existing payload entry
        payloadData[existingPayloadIndex].active = isActive;
      } else {
        // Add a new payload entry
        payloadData.push({
          customerTestCaseId: testCaseId,
          active: isActive,
        });
      }

      // Make API call to update the active status on the server
      // const response = await updateActiveStatus({
      //   customerTestCaseId: testCaseId,
      //   active: isActive,
      // });
      const payload = [{
        testCaseId: testCaseId,
        active: isActive,
      }]
      await updateActiveStatus(payload).unwrap().then((res) => {
        if (res?.status === "success") {
          Snackbar.displayMsg("Test Case Status Updated Successfully", ALERT_SEVERITY.SUCCESS);
        } else {
          Snackbar.displayMsg("TestCase Failed to Update", ALERT_SEVERITY.ERROR);
        }
      })

      // payloadData.push(payload)

      // Handle the API response if needed
      // console.log('API response:', response);
    } catch (error) {
      // Handle error if the API call fails
      console.error('Error updating active status:', error);
    }
  };
  // console.log("payloadData", payloadData);


  columns[2].render = (colData, rec, rowIndex) => {
    return (
      <span onClick={() => onTestcaseClick(rec, rowIndex)}>{rec.testCaseName}</span>
    )
  }

  columns[3].render = (colData, rec) => {
    // console.log('onVersionChange', rec, selectedVersions, selectedVersions[rec.customerTestCaseId],
    //   selectedTestCasePayload?.find(item =>
    //     item.customerTestCaseId === rec?.customerTestCaseId
    //   )
    // );
    const findValue = selectedTestCasePayload?.find(item =>
      item.customerTestCaseId === rec?.customerTestCaseId
    )?.customerTestDataId[0]?.data
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }} onClick={(e) => e.stopPropagation()}>
        <ToggleEllipsis text={colData} maxWidth={(25 / 100) * window.innerWidth} />
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <VersionSelection
            data={rec}
            onChange={(val) => onVersionChange(rec.customerTestCaseId, val, !_.isEmpty(rec.customerTestDataInTestCase) ? _.size(rec.customerTestDataInTestCase) : 0)}
            value={findValue ? findValue : selectedVersions ? selectedVersions[rec.customerTestCaseId]?.data : []}
          />
        </div>
      </div>
    )
  }

  //messageId dropdown
  // console.log('MessageIdDropdown-selectedVersions',selectedVersions);
  columns[6].render = (colData, rec, rowIndex) => {
    // console.log("MessageIdDropdown-coldata",colData , rec , selectedVersions[rec.customerTestCaseId]?.messageId);
    // if (rec.customerTestDataInTestCase?.map(item => item.msgDetails?.length).every(length => length === 1) === false) {
    const findValue = selectedTestCasePayload?.find(item =>
      item.customerTestCaseId === rec?.customerTestCaseId
    )?.customerTestDataId[0]?.messageId
    // console.log("findValue",colData?.msgDetails);
    return (
      // rec.customerTestDataInTestCase?.map(item => item.msgDetails?.length).every(length => length === 0) === false
      // console.log("RECC",rec.customerTestDataInTestCase?.map((item,index) => item?.msgDetails)),
      <div >
        <MessageIdDropdown
          value={findValue ? findValue : selectedVersions ? selectedVersions[rec.customerTestCaseId]?.messageId : []}
          // value={messageId}
          onChange={(val) => onMessageIdChange(rec.customerTestCaseId, val)}
          data={rec}
          testData={colData?.msgDetails} // for one-one testCase 
        />
      </div>
    )
    // }
  }



  {
    (userRole?.name === "Super Admin" ?
      columns[7].render = (colData, rec) => (
        // setIsActive(rec.active),
        <span>
          <XSwitch
            // defaultChecked
            // primary={"Active"} secondary={"InActive"}
            checked={rec.active}
            onChange={(newActiveStatus) => handleActiveInactive(!rec.active, rec.customerTestCaseId)}
            // checked={isActive}

            sx={{
              // Define your styles here
              '& .MuiSwitch-track': {
                backgroundColor: rec.active ? '#009966 !important' : '#ACACAC !important',
                opacity: 1,
              },
            }}
          />
        </span>
      ) : (<></>)
    )
  }
  const [mapData, setMapData] = useState([])
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log("🚀 ~ file: testcaseTable.js:140 ~ onChange:", { selectedRowKeys, selectedRows, selectedVersions });
      dispatch(updateTestScenarioName(selectedRows[0]?.inboundOrOutbound))

      setXselectedRows(selectedRows);
      const mappedData = _.map(selectedRows, d => (
        // console.log('d.customerTestCaseId', selectedTestCasePayload?.find(item => item?.customerTestCaseId === d?.customerTestCaseId)), 
        {
        customerTestCaseId: d?.customerTestCaseId,
        customerTestCaseVersionId: d?.customerTestCaseVerionId,
        // customerTestDataId: _.get(selectedVersions[d.customerTestCaseId], 'value', null)
        //pre-requisite
        customerTestDataId: [{
          data: _.get(selectedVersions[d?.customerTestCaseId], 'data', selectedTestCasePayload?.find(item => item?.customerTestCaseId === d?.customerTestCaseId)?.customerTestDataId[0]?.data),
          messageId: _.get(selectedVersions[d?.customerTestCaseId], 'messageId', selectedTestCasePayload?.find(item => item?.customerTestCaseId === d?.customerTestCaseId)?.customerTestDataId[0]?.messageId)
        }]
      }));
      // console.log("mappedData", mappedData);
      dispatch(updateSelectedTestCasePayload(mappedData))
      setSelectedTestCasesToRun(mappedData, testCaseScenarioId, false);
    },
    getCheckboxProps: (record) => ({
      className: "checkbox-red",
      disabled: disabledSelection,
    }),
  };

  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     console.log("🚀 ~ file: testcaseTable.js:140 ~ onChange:", { selectedRowKeys, selectedRows, selectedVersions });
  //     dispatch(updateTestScenarioName(selectedRows[0]?.inboundOrOutbound))

  //     // Combine existing and newly selected rows
  //     const updatedSelectedRows = selectedRows.map(existingRow => {
  //       const matchingNewRow = selectedRows.find(newRow => newRow.customerTestCaseId === existingRow.customerTestCaseId);
  //       if (matchingNewRow) {
  //         const existingTestDataId = _.get(existingRow, 'customerTestDataId[0].data', null);
  //         const newTestDataId = _.get(selectedVersions[existingRow.customerTestCaseId], 'data', null);
  //         if (existingTestDataId && newTestDataId) {
  //           matchingNewRow.customerTestDataId = [{
  //             data: existingTestDataId,
  //             messageId: null
  //           }];
  //         }
  //       }
  //       return matchingNewRow || existingRow;
  //     });

  //     setXselectedRows(updatedSelectedRows);

  //     const mappedData = updatedSelectedRows.map(d => ({
  //       customerTestCaseId: d.customerTestCaseId,
  //       customerTestCaseVersionId: d.customerTestCaseVerionId,
  //       customerTestDataId: d.customerTestDataId,
  //     }));

  //     console.log("mappedData", mappedData);
  //     setSelectedTestCasesToRun(mappedData, testCaseScenarioId, false);
  //   },
  //   getCheckboxProps: (record) => ({
  //     className: "checkbox-red",
  //     disabled: disabledSelection,
  //   }),
  // };


  const onPageChange = (pageNo) => {
    setPageOptions({
      ...pageOptions,
      pageNo,
    })
  }
  return (
    <PayaptTable
      size='small'
      rowClassName={(record, index) => index % 2 === 0 ? 'table-row' : 'table-row'}
      // rowClassName={() => 'table-row-dark'}
      className='nested_table'
      columns={columns}
      rowSelection={{
        ...rowSelection,
        selectedRowKeys: selectedTestCases,
        preserveSelectedRowKeys: true
      }}
      rowKey={(rec) => rec.customerTestCaseId}
      // onRow={(record, rowIndex) => {
      //   return {
      //     onClick: event => onTestcaseClick(record, rowIndex), // click row
      //   };
      // }}
      showHeader={false}
      data={!_.isEmpty(testCases) ? testCases : []}
      loading={isLoading}
      onChange={(pagination, filters, sorter) =>
        onPageChange(pagination.current - 1)
      }
      pagination={{
        pageSize,
        current: !_.isEmpty(data) ? data.pageNo + 1 : 1,
        total: !_.isEmpty(data) ? data.totalElements : 0,
        size: 'small',
        hideOnSinglePage: true
      }}
      scroll={null}
    />

  )
}

export default TestcaseTable;

const VersionSelect = ({ item }) => {
  const [value, setValue] = React.useState([]);
  const dpOptions = item?.versions?.map((xEl) => ({
    id: xEl?.customerTestDataId,
    label: xEl?.testDataNumber,
    value: xEl?.testDataNumber,
    isClonedCopy: xEl?.isClonedCopy
  }));

  // console.log("🚀 ~ file: testcaseTable.js:141 ~ dpOptions:", { item, dpOptions })
  const handleOnChange = (xSelOpt) => {
    // console.log("🚀 ~ file: testcaseTable.js:147 ~ handleOnChange ~ xSelOpt:", { xSelOpt });
    setValue((oldArr) => [...oldArr, xSelOpt?.id]);
  };

  return (
    <Dropdown
      multiple
      value={value}
      onChange={handleOnChange}
      options={dpOptions}
      defaultValue={dpOptions[0] ? dpOptions[0] : []}
    />
  )
}


// customerTestDataId:"6408c6da361aa36302af6256"
// isClonedCopy:"NO"
// testDataNumber:"Test Data-1"