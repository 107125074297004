import React, { useContext, useEffect, useState } from 'react';
import Dropdown from '../../shared/components/Dropdown';
import _ from 'lodash';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';
import { useGetPaymentByCustomerQuery, useGetPaymentServiceQuery } from '../../redux/customers/customer.api';
import { useSelector } from 'react-redux';

const styles = {
    menuProps: {
        sx: {
            '.MuiPaper-root': {
                maxHeight: '40%',
            },
        },
    },
}


const FedTchDropdown = (props) => {
    // const { onChangeFedTch, value } = props;
    const { value, changeValue,customer} = useContext(DropdownValueContext);
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const { paymentServices, paymentId } = useSelector((state) => state?.common);
    const { customerId } = useSelector((state) => state?.common);
    const {data: paymentOptions} = useGetPaymentByCustomerQuery({customerId: (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer});

    const onFedTchSelection = (event) => {
        changeValue(event)
    };
    
    
    const applicablePaymentServices = paymentId?.filter(service => service.isApplicable);

    return (
        <>
             {/* {showDropdown && ( */}
                <Dropdown
                    placeholder='Select Payment Service'
                    margin="dense"
                    className="user-dropdown"
                    style={{ width: '100%', height: '42px', marginTop: '-20px' }}
                    inputProps={{ 'aria-label': 'Without label' }}
                    options={paymentOptions}
                    valueKey='paymentServiceId'
                    labelKey='paymentServiceName'
                    onChange={onFedTchSelection}
                    value={value}
                    MenuProps={styles.menuProps}
                />
            {/* )} */}
        </>
    );
}

export default FedTchDropdown;
