import { Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import TestCaseHeader from '../testCaseHeader';
import moment from 'moment';
import RoutingDropdown from '../SystemMessages/RoutingDropdown';
import MUIButton from '../../shared/components/MUIButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import { ALERT_SEVERITY, APP_ROUTE_KEYS } from '../../shared/constants/constants';
import ValueDropdown from './ValueDropdown';
import MessageDropdown from './MessageDropdown';
import { useSelector } from 'react-redux';
import { useContext } from 'react';
import { SnackbarContext } from '../../shared/context/SnackbarContext.Context';
import { DropdownValueContext } from '../../shared/context/DropdownProvider';
import FlowTypeDropdown from './FlowTypeDropdown';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import * as yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PayaptTextField from '../../shared/components/TextField';
import RtnDropdown from './RtnDropdown';



const Admi006 = () => {
    const navigate = useNavigate();
    const Snackbar = useContext(SnackbarContext);
    const { value, customer } = useContext(DropdownValueContext);
    const { customerId } = useSelector((state) => state?.common);
    const { userRole } = useSelector((state) => state?.common?.userDetails);
    const pageSize = useSelector((state) => state?.common?.pageSize);
    const [date, setDate] = useState(moment());
    const [valuee, setValuee] = useState();
    const [valMsg, setValMsg] = useState();
    const [flowMsg, setFlowMsg] = useState();
    const [rtnNum, setRtnNum] = useState('');
    const [fields, setFields] = useState([{ fromSeq: '', toSeq: '' }]);
    const currentDate = moment().format('MM/DD/YYYY');


    const validationSchema = yup.object({
        messageId: yup
            .string().nullable()
            .matches(/^[a-zA-Z0-9]*$/, "Only alphanumeric characters are allowed"),
        // .required("Message ID is required"),
    });

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const onDateSelection = (event) => {
        setDate(event)
    };
    const onValueSelection = (event) => {
        setValuee(event)
    };

    const handleAddField = () => {
        setFields([...fields, { fromSeq: '', toSeq: '' }]);
    };

    const handleRemoveField = (index) => {
        setFields(fields.filter((_, i) => i !== index));
    };

    const handleChange = (index, field, value) => {
        const updatedFields = [...fields];
        updatedFields[index][field] = value;
        setFields(updatedFields);
    };

    const handleViewMessage = (data) => {
        
        if (valuee === undefined || valMsg === undefined) {
            Snackbar.displayMsg("Please Select the Values", ALERT_SEVERITY.ERROR)
        } else {
            const sequenceNumber = fields.every(item => item.fromSeq === "" && item.toSeq === "")
                ? null
                : fields.map(item => ({
                    fromSeq: item.fromSeq === "" ? null : item.fromSeq,
                    toSeq: item.toSeq === "" ? null : item.toSeq
                }));
            const paylod = {
                "messageName": valMsg,
                "fromDate": date[0] ? moment(new Date(date[0])).format() : null,
                "toDate": date[1] ? moment(new Date(date[1])).format() : null,
                "search": null,
                "page": 0,
                "size": pageSize,
                "cusId": (userRole?.name === 'Admin' || userRole?.name === 'Tester') ? customerId : customer,
                "paymentServiceId": value,
                "flowType": flowMsg ? flowMsg : null,
                "sequenceNumber": sequenceNumber,
                "messageId": data?.messageId ? data?.messageId : null,
                "rtn": rtnNum ? rtnNum : null
            }
            
            navigate(`${APP_ROUTE_KEYS.REPORTS.MESSAGE_ADMI006}`, { state: { paylod } })
        }

    }
    const onMessageSelect = (event) => {
        setValMsg(event);
    };
    const onFlowSelect = (event) => {
        setFlowMsg(event);
    };
    const disabledDate = (current) => {
        const today = moment().endOf('day');
        const sevenDaysAgo = today.clone().subtract(8, 'days');
        return current && (current > today || current < sevenDaysAgo);
    };

    const onRTNSelection = (e) => {
        setRtnNum(e);
    };

    return (
        <Box sx={{ overflowY: 'auto', height: `calc(100vh - 100px)`, paddingBottom: "30px" }}>
            <TestCaseHeader
                title="Admi.006-Retrieval Request Report"
                displayInLine={true}
                showCustomer={{
                    show: true,
                }}
                showTchDropdown={{
                    show: true,
                }}
                showDateFromToPicker={{
                    show: true,
                    disabledDate: disabledDate,
                    onChange: onDateSelection,
                    value: date
                }}
            />

            {/* <Box mt={"2%"} sx={{ px: '2.1%' }}>
                <Box>
                    <Grid container>
                        <Grid item width={"40%"} mt={1}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                Select Routing Number
                            </Typography>
                        </Grid>
                        <Grid item mt={3} width={"25%"}>
                            <RoutingDropdown/>
                        </Grid>
                    </Grid>
                </Box>
            </Box> */}
            <Box mt={"2%"} sx={{ px: '2.1%' }}>
                <Box>
                    <Grid container>
                        <Grid item width={"40%"} mt={1}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                Select Message Type
                            </Typography>
                        </Grid>
                        <Grid item mt={3} width={"25%"}>
                            <ValueDropdown onChangeValue={onValueSelection} value={valuee} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box mt={"2%"} sx={{ px: '2.1%' }}>
                <Box>
                    <Grid container>
                        <Grid item width={"40%"} mt={1}>
                            <Typography sx={{ display: "flex", fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "bold", fontSize: "17px" }}>
                                Select Messages
                            </Typography>
                        </Grid>
                        <Grid item mt={3} width={"25%"}>
                            <MessageDropdown messageType={valuee} onChange={onMessageSelect} value={valMsg} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            {(3 === value) && (
                <>
                    <Box mt={"2%"} sx={{ px: '2.1%' }}>
                        <Box>
                            <Grid container>
                                <Grid item width={"40%"} mt={1}>
                                    <Typography
                                        sx={{
                                            display: "flex",
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: "bold",
                                            fontSize: "17px",
                                        }}
                                    >
                                        Select Flow Type
                                    </Typography>
                                </Grid>
                                <Grid item mt={3} width={"25%"}>
                                    <FlowTypeDropdown flowType={flowMsg} onChangeFlow={onFlowSelect} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                    {/* Dynamic "From" and "To" Fields Section */}
                    <Box mt={'2%'} sx={{ px: '2.1%' }}>
                        <Grid container>
                            {/* Label Row */}
                            <Grid item width={'40%'} mt={1}>
                                <Typography
                                    sx={{
                                        display: 'flex',
                                        fontFamily: 'Inter',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        fontSize: '17px',
                                    }}
                                >
                                    Enter Sequence Number
                                </Typography>
                            </Grid>

                            {/* Dynamic Field Rows */}
                            <Grid item xs={5} sm={6}>
                                {fields?.map((field, index) => (
                                    <Grid
                                        container
                                        key={index}
                                        spacing={2}
                                        alignItems="center"
                                        sx={{
                                            marginTop: index === 0 ? '0px' : '3px', // Add spacing between rows
                                        }}
                                    >
                                        {/* From TextField */}
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                id={`fromSeq-${index}`}
                                                name="fromSeq"
                                                label="From"
                                                autoComplete='off'
                                                value={field.fromSeq}
                                                onChange={(e) => handleChange(index, 'fromSeq', e.target.value)}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                    pattern: '[0-9]*',
                                                }}
                                            />
                                        </Grid>

                                        {/* To TextField */}
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                id={`toSeq-${index}`}
                                                name="toSeq"
                                                label="To"
                                                autoComplete='off'
                                                value={field.toSeq}
                                                onChange={(e) => handleChange(index, 'toSeq', e.target.value)}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                    pattern: '[0-9]*',
                                                }}
                                            />
                                        </Grid>

                                        {/* Add or Remove icons */}
                                        <Grid item xs={4} container justifyContent="flex-start">
                                            {/* Remove icon for all rows except the first */}
                                            {fields.length > 1 && (
                                                <IconButton onClick={() => handleRemoveField(index)}>
                                                    <RemoveCircleOutlineIcon />
                                                </IconButton>
                                            )}
                                            {/* Add icon for the last row only */}
                                            {index === fields.length - 1 && (
                                                <IconButton onClick={handleAddField}>
                                                    <AddCircleOutlineIcon />
                                                </IconButton>
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Box>


                    <Box mt={"2%"} sx={{ px: '2.1%' }}>
                        <Box>
                            <Grid container>
                                <Grid item width={"40%"} mt={1}>
                                    <Typography
                                        sx={{
                                            display: "flex",
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: "bold",
                                            fontSize: "17px",
                                        }}
                                    >
                                        Enter Message ID
                                    </Typography>
                                </Grid>
                                <Grid item width={"25%"}>
                                    <Controller
                                        name="messageId"
                                        control={control}
                                        render={({ field }) => (
                                            <PayaptTextField
                                                // {...register("messageId")}
                                                {...field}
                                                name="messageId"
                                                placeholder='Enter Message ID'
                                                className="user-text-box"
                                                error={!!errors.messageId}
                                                // helperText={errors['firstName']?.message}
                                                helperText={errors.messageId ? errors.messageId.message : ""}
                                            />

                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                    <Box mt={'2%'} sx={{ px: '2.1%' }}>
                        <Box>
                            <Grid container>
                                <Grid item width={'40%'} mt={1}>
                                    <Typography
                                        sx={{
                                            display: 'flex',
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: 'bold',
                                            fontSize: '17px',
                                        }}
                                    >
                                        Select Routing Number
                                    </Typography>
                                </Grid>
                                <Grid item mt={3}>
                                    <RtnDropdown
                                        onChangeRtn={onRTNSelection}
                                        value={rtnNum}
                                        customer={
                                            userRole?.name === 'Admin' || userRole?.name === 'Tester'
                                                ? customerId
                                                : customer
                                        }
                                        paymentValue={value}

                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                </>
            )}



            <Box mt={"20%"} sx={{ display: "flex", px: '2.1%' }}>
                <MUIButton
                    startIcon={<CheckCircleIcon style={{ fontSize: 18, }} />}
                    title="View Message"
                    onClick={handleSubmit(handleViewMessage)}
                />
            </Box>
        </Box>
    )
}

export default Admi006;
