import React, { useEffect } from 'react';
import Dropdown from '../../shared/components/Dropdown';
import { useGetReportDropdownQuery } from '../../redux/customers/customer.api';
import _ from 'lodash';


const styles = {
    menuProps: {
        sx: {
            '.MuiPaper-root': {
                maxHeight: '40%',
            },
        },
    },
}

const ReportDropdown = (props) => {
    const {value, onChangeReport, paymentServiceId} = props;
    const {data: reportOptions} = useGetReportDropdownQuery({paymentServiceId:paymentServiceId});

    useEffect(() => {
        if (!_.isEmpty(reportOptions)) {
            onChangeReport(reportOptions?.id, reportOptions?.Value)
        }
    },[reportOptions])

    const onChange = (value) => {
        if (value) {
            const obj = _.find(reportOptions, m => m?.id === value);
            onChangeReport(value, obj?.Value)
        } else {
           onChangeReport(value,value) 
        }
    }
    return (
        <>
            <Dropdown
                placeholder=' Select Report Type'
                margin="dense"
                className="user-dropdown"
                style={{ width: '100%', height: '42px', marginTop: '-20px' }}
                inputProps={{ 'aria-label': 'Without label' }}
                options={reportOptions}
                valueKey='Value'
                labelKey='Value'
                onChange={(e) => onChange(e)}
                value={value}
                MenuProps={styles.menuProps}
            //    isClear={isClear}
            />
        </>
    )
}

export default ReportDropdown;
